import { Box, Container, Typography } from "@mui/material";
import React from "react";
import DemoBox from "./DemoBox";
import "../home/Demo.scss";

export default function Demo() {
  return (
    <section className="demoBack">
      <Container>
        <Box className="demo-Wrapper">
          <Typography variant="h2">
            Create an absolute user experience & watch your business grow
          </Typography>
          <Typography variantMapping="p" className="demo-Wrapper-Content">
            Increase product sales, and customer engagement, generate qualified
            leads and create an instant response with your customers using
            Invocom AI chatbot technology.
          </Typography>
          <DemoBox />
        </Box>
      </Container>
    </section>
  );
}
