import * as React from "react";
import Layout from "../components/base-structure/Layout";
import HeroSection from "../components/home/HeroSection";
import Communication from "../components/home/Communication";
import Demo from "../components/home/Demo";
import ChatbotSection from "../components/home/ChatbotSection";
import AbidCommunication from "../components/home/AbidCommunication";
import Revenue from "../components/home/Revenue";
import InituativeServices from "../components/home/InituativeServices";
import LeadGenerate from "../components/home/LeadGenerate";
import FractionCost from "../components/home/FractionCost";
import Consumer from "../components/home/Consumer";
import Blog from "../components/home/Blog";
import Faq from "../components/home/FAQ";
import Customizable from "../components/home/Customizable";
import Logos from "../components/home/Logos";
import VideoModal from "../components/home/VideoModal";
import { graphql } from "gatsby";
import Seo from "../components/common/SEO";
import Effective from "../components/home/Effective";
import { Helmet } from "react-helmet";

const content = [
  {
    id: 1,
    metaTitle: "Invocom | Best customer service anytime, anywhere!",
    metaDescription:
      "Invocom offers AI chatbot services for customer support. It assists in qualifying leads, gives a personalized experience, and increases your revenue.",
  },
];

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <meta name="robots" content="index, follow" />
      <meta name="robots" content="max-image-preview:standard" />
    </Helmet>
    {content.map((contents) => (
      <div key={contents.id}>
        <Seo
          title={contents?.metaTitle}
          description={contents?.metaDescription}
          tag="index"
          pageUrl=""
        />
      </div>
    ))}
    <HeroSection />
    <Communication />
    <Demo />
    <ChatbotSection />
    <VideoModal />
    <AbidCommunication />
    <Revenue />
    <Effective />
    <InituativeServices />
    <LeadGenerate />
    <FractionCost />
    <Consumer />
    <Blog blogs={data?.allStrapiBlog?.nodes} />
    <Faq />
    <Customizable
      indexprop={1}
      title="Ready to sign up for an extremely customizable experience?"
      background="#611F69"
      textColor="#FBF7EE"
      pointsColor="#FBF7EE"
    />
    <Logos />
    <script type="application/ld+json">
      {`{
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
        {
            "@type": "Question",
            "name": "How to integrate the InvoCom chatbot into your website?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can seamlessly integrate a chatbot in less than 30 seconds. All you have to do is copy-paste the snippet code and deploy them to your website."
            }
        },
        {
            "@type": "Question",
            "name": "How much does InvoCom cost?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "InvoCom is free to use. You can see further details about the features that we are offering for free from here."
            }
        },
        {
            "@type": "Question",
            "name": "What features do I get with InvoCom?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "You get the following features with InvoCom:
Custom Bot, Robust Analytics, Ticketing system, CSV Export, Email Support, Knowledge base, Live Chat, Calendar Integration, Meeting scheduler, Admins, Tag & assign chat, Push notifications, Single team inbox, Conversations dashboard, Smart assign, Note-taking."
            }
        },
        {
            "@type": "Question",
            "name": "How to enable desktop notifications for InvoCom?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Sign in to your account, from the left-hand panel, choose the ‘settings’ option and select ‘notifications’ to change settings accordingly."
            }
        }
    ]
}`}
    </script>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query MyQueryZaman {
    allStrapiBlog(sort: { fields: createdAt, order: DESC }) {
      nodes {
        Content
        Slug
        Title
        id
        Image {
          url
        }
        createdAt
        author_profile {
          Slug
          Name
        }
        categories {
          Name
          Slug
        }
      }
    }
  }
`;
