import React from "react";

const FAQ = [
  {
    question: "How to integrate the InvoCom chatbot into your website?",
    answer: (
      <span>
        You can seamlessly integrate a chatbot in less than 30 seconds. All you
        have to do is copy-paste the snippet code and deploy them to your
        website.
      </span>
    ),
    panel: "panel1",
  },
  {
    question: "How much does InvoCom cost?",
    answer: (
      <span>
        InvoCom is free to use. You can see further details about the features
        that we are offering for free from here.
      </span>
    ),
    panel: "panel2",
  },
  {
    question: "What features do I get with InvoCom?",
    answer: (
      <>
        <span style={{ fontWeight: "500" }}>
          You get the following features with InvoCom:
        </span>
        <li style={{ paddingLeft: "1rem" }}> Custom Bot</li>
        <li style={{ paddingLeft: "1rem" }}>Robust Analytics</li>
        <li style={{ paddingLeft: "1rem" }}>Ticketing system</li>
        <li style={{ paddingLeft: "1rem" }}>CSV Export</li>
        <li style={{ paddingLeft: "1rem" }}>Email Support</li>
        <li style={{ paddingLeft: "1rem" }}>Knowledge base</li>
        <li style={{ paddingLeft: "1rem" }}>Live Chat</li>
        <li style={{ paddingLeft: "1rem" }}>Calendar Integration</li>
        <li style={{ paddingLeft: "1rem" }}>Meeting scheduler</li>
        <li style={{ paddingLeft: "1rem" }}>Admins</li>
        <li style={{ paddingLeft: "1rem" }}>Tag & assign chat</li>
        <li style={{ paddingLeft: "1rem" }}>Push notifications</li>
        <li style={{ paddingLeft: "1rem" }}>Admins</li>
        <li style={{ paddingLeft: "1rem" }}>Single team inbox</li>
        <li style={{ paddingLeft: "1rem" }}>Conversations dashboard</li>
        <li style={{ paddingLeft: "1rem" }}>Smart assign</li>
        <li style={{ paddingLeft: "1rem" }}>Note-taking</li>
      </>
    ),
    panel: "panel3",
  },
  {
    question: "How to enable desktop notifications for InvoCom?",
    answer: (
      <span>
        Sign in to your account, from the left-hand panel, choose the ‘settings’
        option and select ‘notifications’ to change settings accordingly.
      </span>
    ),
    panel: "panel4",
  },
];

export default FAQ;
