import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Communicate from "../../images/communicate.png";
import "../home/Communication.scss";

export default function Communication() {
  return (
    <section className="communicateBack">
      <Container>
        <Box className="communicate-wrapper">
          <Box className="communicate-content">
            <Typography variant="h2">
              Engaging Chatbot for Seamless Communication
            </Typography>
            <Typography variantMapping="p">
              We believe every customer interaction is an opportunity to build a
              long-lasting relationship.
            </Typography>
          </Box>
          <Box className="communicate-image">
            <img
              loading="lazy"
              className="communicateImage"
              src={Communicate}
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </section>
  );
}
