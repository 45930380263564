import React from "react";
import { Box, Typography } from "@mui/material";
import "../home/Revenue.scss";

export default function RevenueBoxes() {
  return (
    <Box className="Boxes-wrapper">
      <Box className="box-row">
        {/* Box 1 */}
        <Box className="box-content">
          <Typography variantMapping="p" className="digitsRevenue">
            2x
          </Typography>
          <Typography variantMapping="p" className="contentRevenue">
            More quality
            <br />
            leads
          </Typography>
        </Box>
        {/* Box 2 */}
        <Box className="box-content">
          <Typography variantMapping="p" className="digitsRevenue">
            35-40%
          </Typography>
          <Typography variantMapping="p" className="contentRevenue">
            Higher response
            <br />
            rates
          </Typography>
        </Box>
      </Box>

      <Box className="box-row">
        {/* Box 3 */}
        <Box className="box-content">
          <Typography variantMapping="p" className="digitsRevenue">
            30%
          </Typography>
          <Typography variantMapping="p" className="contentRevenue">
            Customer service
            <br />
            cost reduction
          </Typography>
        </Box>
        {/* Box 4 */}
        <Box className="box-content">
          <Typography variantMapping="p" className="digitsRevenue">
            87.58%
          </Typography>
          <Typography variantMapping="p" className="contentRevenue">
            Improved customer
            <br />
            satisfaction
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
