import React from "react";
import Container from "@mui/material/Container";
import { Box, Button, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import "../home/HeroSection.scss";
import { Link } from "gatsby";

export default function HeroSection() {
  return (
    <section className="homeBack">
      <Container>
        <Box className="header">
          <Typography variant="h1">
            Qualify Leads and Automate Customer Support
            <br />
            with AI-Powered
            <span className="chat"> Chatbot</span>
          </Typography>
        </Box>
        <Box className="buttons_Div">
          <Link to="https://app.invocom.io/signup/" target="_blank">
            <Button className="free_Trial" id="free_Trial_btn">
              Start Free Trial
            </Button>
          </Link>
          <Link to="https://calendly.com/invocom/book-a-demo" target="_blank">
            <Button className="free_Demo" id="free_Demo_btn">
              Book a free demo
            </Button>
          </Link>
        </Box>
        <Box className="points-wrapper">
          <Box className="point-content">
            <DoneIcon
              sx={{
                "@media screen and (max-width: 576px)": {
                  fontSize: "17px",
                },
              }}
            />
            <Typography variant="span">14-day free trial</Typography>
          </Box>
          <Box className="point-content">
            <DoneIcon
              sx={{
                "@media screen and (max-width: 576px)": {
                  fontSize: "17px",
                },
              }}
            />
            <Typography variant="span">Full features enabled</Typography>
          </Box>
          <Box className="point-content">
            <DoneIcon
              sx={{
                "@media screen and (max-width: 576px)": {
                  fontSize: "17px",
                },
              }}
            />
            <Typography variant="span">No credit card is required</Typography>
          </Box>
        </Box>
        <Box className="reach-out">
          <Typography variant="span">
            Reach out to prospects, deliver personalized experiences, increase
            conversion rates, and ultimately boost your revenues with InvoCom’s
            purpose-built chatbot.
          </Typography>
        </Box>
      </Container>
    </section>
  );
}
