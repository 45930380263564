import React, { Component } from "react";
import { Container, Typography, Box } from "@mui/material";
import "../home/VideoModal.scss";
// import ReactPlayer from "react-player/youtube";
// import Videomodal from "../../images/video-thumbnail.svg";
import "../home/VideoModal.scss";
export default class VideoModal extends Component {
  render() {
    return (
      <section className="modalBack">
        <Container>
          <Box className="modal-wrapper">
            <Box className="modal-content">
              <Typography variant="h2">
                Make Customer Engagement More Interactive with InvoCom
              </Typography>
              <Typography variantMapping="p">
                Helping your customer service to monitor how customers engage
                with your website and offer targeted solutions.
              </Typography>
            </Box>
            <Box className="video-wrapper">
              {/* <ReactPlayer
               className="react-player"
               url="https://fb.watch/jGoPMOyMyv/"
               light={Videomodal}
               controls={true}
               playIcon={<span />}
               playing={true}
               width="100%"
               height="100%"
             /> */}
              <iframe
                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Finvocomofficial%2Fvideos%2F1055927135065238%2F&show_text=false&width=560&t=0"
                width="560"
                height="314"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
                title="InvoCom"
              />
            </Box>
          </Box>
        </Container>
      </section>
    );
  }
}
