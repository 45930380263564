import React from "react";
import { Container, Typography, Box } from "@mui/material";
import InitiateSlider from "./InitiateSlider";
import "../home/InituativeServices.scss";

export default function InituativeServices() {
  return (
    <section className="servicesBack">
      <Container>
        <Box className="services-heading">
          <Typography variant="h2">
            Delivering the Most Intuitive Services
          </Typography>
        </Box>
        <InitiateSlider />
      </Container>
    </section>
  );
}
