import React from "react";
import { Link } from "gatsby";
import { Container, Typography, Button, Box } from "@mui/material";
import FreeBox from "./FreeBox";
import "../home/FractionCost.scss";

export default function FractionCost() {
  return (
    <section className="fractionBack">
      <Container>
        <Box className="fractionCost-heading">
          <Typography variant="h2">
            Configure InvoCom for a Fraction of a Cost
          </Typography>
        </Box>
        <Box className="fractionBox">
          <FreeBox />
        </Box>
        <Box className="fractionCost-btn-wrapper">
          <Link to="/pricing/">
            <Button className="fractionCost-btn" id="fraction_Cost_btn">
              Explore Pricing
            </Button>
          </Link>
        </Box>
      </Container>
    </section>
  );
}
