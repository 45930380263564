import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Consumer1 from "../../images/consumer1.png";
import Consumer2 from "../../images/consumer2.png";
import Consumer3 from "../../images/consumer3.png";
import Consumer4 from "../../images/consumer4.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../home/Consumer.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      className={`${className} NextConsume`}
      onClick={onClick}
      sx={{
        "&::before": {
          display: "none",
        },
      }}
      style={{ ...style, display: "block", top: "150px", right: "-30px" }}
    >
      {/* <img
        loading="lazy"
        src={Right}
        alt=""
        style={{ ...style, width: "80px" }}
      /> */}
      <ArrowForwardIcon
        sx={{
          fontSize: "40px",
          fill: "#611F69",
          background: "#FBF7EE",
          padding: "10px",
          borderRadius: "40px",
          stroke: "#611F69",
          strokeLinejoin: "round",
          transition: "0.1s ease",
          "&:hover": {
            background: "#FCB92B",
          },
          "@media screen and (max-width: 576px)": {
            fontSize: "25px",
          },
        }}
      />
    </Box>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      className={`${className} PrevConsume`}
      sx={{
        "&::before": {
          display: "none",
        },
      }}
      onClick={onClick}
      style={{ ...style, display: "block", top: "150px", left: "-75px" }}
    >
      {/* <img
        loading="lazy"
        src={Left}
        alt=""
        style={{ ...style, width: "80px" }}
      /> */}
      <ArrowBackIcon
        sx={{
          fontSize: "40px",
          fill: "#611F69",
          background: "#FBF7EE",
          padding: "10px",
          borderRadius: "40px",
          stroke: "#611F69",
          strokeLinejoin: "round",
          transition: "0.1s ease",
          "&:hover": {
            background: "#FCB92B",
          },
          "@media screen and (max-width: 576px)": {
            fontSize: "25px",
          },
        }}
      />
    </Box>
  );
}

const consumers = [
  {
    id: 1,
    image: Consumer1,
    title: "Marilyn  Walker",
    review:
      "“The InvoCom team is highly receptive to feedback in times of need.”",
  },
  {
    id: 2,
    image: Consumer3,
    title: "Paul Tran",
    review:
      "“InvoCom enhanced our lead generation, qualification, and nurturing initiatives by 37%.”",
  },
  {
    id: 3,
    image: Consumer4,
    title: "Steven Cerda",
    review:
      "“A very pleasant and user-friendly user interface with a handful of meaningful features.”",
  },
  {
    id: 4,
    image: Consumer2,
    title: "Jason Foster",
    review:
      "“The analytics feature stood out for us as we could stay updated with the trends and pending chats.”",
  },
];

export default function ConsumerSlider() {
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <Container className="consumer-slider-container">
      <Slider {...settings}>
        {consumers.map((contents) => (
          <React.Fragment key={contents.id}>
            <Box className="consumer-slide-wrapper">
              <Box className="consumer-slide-image">
                <img loading="lazy" src={contents.image} alt="" />
              </Box>
              <Box className="consumer-slide-content">
                <Typography variant="h3">{contents.title}</Typography>
                <Typography variantMapping="p">{contents.review}</Typography>
              </Box>
            </Box>
          </React.Fragment>
        ))}
      </Slider>
    </Container>
  );
}
