import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ChatBot from "../../images/AliceOne.svg";
import "../home/ChatbotSection.scss";

export default function ChatbotSection() {
  return (
    <section className="chatBack">
      <Container>
        <Box className="chatbot-wrapper">
          <Box className="chatbot-image">
            <img loading="lazy" className="chatImage" src={ChatBot} alt="" />
          </Box>
          <Box
            className="chatbot-content"
            sx={{
              flex: "1",
            }}
          >
            <Typography variant="h2">
              Customer service is great & produces higher sales when combined
              with AI
            </Typography>
            <Typography variantMapping="p">
              Invocom combines Live chat and automotive interactive conversation
              with an AI chatbot to provide an excellent customer experience and
              drive more sales.
            </Typography>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
