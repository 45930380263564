import React from "react";
import { Box, Typography } from "@mui/material";
import "../home/Demo.scss";

const cardContent = [
  {
    number: "1",
    text: "Request live demo",
  },
  {
    number: "2",
    text: "Share requirements",
  },
  {
    number: "3",
    text: "Get a quote",
  },
  {
    number: "4",
    text: "Setup your chatbot",
  },
];

export default function DemoBox() {
  return (
    <Box className="demo-features">
      {cardContent.map((content, key) => (
        <Box key={key}>
          <Box
            className="demoNumber"
            sx={
              key === 1 || key === 2
                ? {
                    "@media screen and (max-width:768px)": {
                      backgroundColor: `#FBF7EE !important`,
                    },
                  }
                : ""
            }
          >
            <Typography
              variantMapping="p"
              className={
                key === 0 || key === 3 ? "aspectsNumber" : "aspectsNumberTwo"
              }
            >
              {content.number}
            </Typography>
          </Box>
          <Box
            className="demoText"
            sx={
              key === 1 || key === 2
                ? {
                    "@media screen and (max-width:768px)": {
                      backgroundColor: `#FBF7EE !important`,
                    },
                  }
                : ""
            }
          >
            <Typography
              variantMapping="p"
              className="aspects"
              sx={
                key === 0 || key === 3
                  ? {
                      "@media screen and (max-width:768px)": {
                        color: `#FBF7EE !important`,
                      },
                    }
                  : ""
              }
            >
              {content.text}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
