import React from "react";
import { Container, Box, Typography } from "@mui/material";
import LGBoxes from "./LGBoxes";
import "../home/LeadGenerate.scss";

export default function LeadGenerate() {
  return (
    <section className="leadBack">
      <Container>
        <Box>
          <Box className="leadGenerate-heading">
            <Typography variant="h2">
              Automated Customer Support &<br />
              Lead-Generation Engine
            </Typography>
          </Box>
          <LGBoxes />
        </Box>
      </Container>
    </section>
  );
}
