import React from "react";
import { Box, Container, Typography } from "@mui/material";
import EffectiveImage from "../../images/John.svg";
import "../home/ChatbotSection.scss";

export default function Effective() {
  return (
    <section className="chatBack">
      <Container>
        <Box className="chatbot-wrapper">
          <Box className="chatbot-image">
            <img
              loading="lazy"
              className="chatImage"
              src={EffectiveImage}
              alt=""
            />
          </Box>
          <Box
            className="chatbot-content"
            sx={{
              flex: "1",
            }}
          >
            <Typography variant="h2">
              An effective & powerful all-in-one customer support tool
            </Typography>
            <Typography variantMapping="p">
              Start instant messaging with customers and gain potential leads
              with higher sales.
            </Typography>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
