import React from "react";
import { Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "../../styles/global.css";
import { Link } from "gatsby";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../home/FAQ.scss";
import FAQ from "../../Data/FAQ";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">-</div>
      <div className="collapsIconWrapper">+</div>
    </Box>
  );
};

export default function FAQAccordion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      sx={{
        maxWidth: "780px",
        margin: "0 auto",
        "@media screen and (max-width:1100px)": {
          maxWidth: "700px",
        },
        "@media screen and (max-width:992px)": {
          maxWidth: "600px",
        },
        "@media screen and (max-width:768px)": {
          maxWidth: "500px",
        },
        "@media screen and (max-width: 576px)": {
          maxWidth: "450px",
        },
        "@media screen and (max-width:428px)": {
          maxWidth: "350px",
        },
      }}
    >
      {/* Accordion 1 starts */}
      {FAQ.map((content, i) => (
        <Accordion
          expanded={expanded === `${content.panel}`}
          onChange={handleChange(`${content.panel}`)}
          sx={{
            marginBottom: "20px",
            boxShadow: "none",
            backgroundColor: "#DDDDDD",
          }}
          key={i}
        >
          <AccordionSummary
            sx={{
              padding: "10px 15px",
            }}
            expandIcon={<CustomExpandIcon />}
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: "18px",
                fontFamily: "Poppins",
                lineHeight: "25px",
                fontWeight: "500",
                "@media screen and (max-width:992px)": {
                  fontSize: "16px",
                },
                "@media screen and (max-width:428px)": {
                  fontSize: "14px",
                },
              }}
            >
              {content.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: "18px",
                fontFamily: "Poppins",
                lineHeight: "25px",
                fontWeight: "400",
                "@media screen and (max-width:992px)": {
                  fontSize: "16px",
                },
                "@media screen and (max-width:768px)": {
                  fontSize: "14px",
                },
                "@media screen and (max-width:428px)": {
                  fontSize: "12px",
                  lineHeight: "20px",
                },
              }}
            >
              {content.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Accordion 4 Ends */}
      <Box className="viewMore">
        <Link to="/faqs/">
          <Typography variantMapping="p">View more</Typography>
        </Link>
        <ArrowForwardIcon
          sx={{ fill: "#6C6C6C", fontSize: "22px", marginLeft: "10px" }}
        />
      </Box>
    </Box>
  );
}
