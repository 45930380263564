import React from "react";
import { Box, Container } from "@mui/material";
// import Left from "../../images/leftArrow.svg";
// import Right from "../../images/rightArrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogBox from "../common/BlogBox";
import "../../styles/Blog.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      className={`${className} NextBlog`}
      onClick={onClick}
      sx={{
        "&::before": {
          display: "none",
        },
      }}
      style={{ ...style, display: "block", top: "150px", right: "-30px" }}
    >
      {/* <img
        loading="lazy"
        src={Right}
        alt=""
        style={{ ...style, width: "80px" }}
      /> */}
      <ArrowForwardIcon
        sx={{
          fontSize: "40px",
          fill: "#611F69",
          background: "#FBF7EE",
          padding: "10px",
          borderRadius: "40px",
          stroke: "#611F69",
          strokeLinejoin: "round",
          transition: "0.1s ease",
          "&:hover": {
            background: "#FCB92B",
          },
          "@media screen and (max-width: 576px)": {
            fontSize: "25px",
          },
        }}
      />
    </Box>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      className={`${className} PrevBlog`}
      sx={{
        "&::before": {
          display: "none",
        },
      }}
      onClick={onClick}
      style={{ ...style, display: "block", top: "150px", left: "-75px" }}
    >
      {/* <img
        loading="lazy"
        src={Left}
        alt=""
        style={{ ...style, width: "80px" }}
      /> */}
      <ArrowBackIcon
        sx={{
          fontSize: "40px",
          fill: "#611F69",
          background: "#FBF7EE",
          padding: "10px",
          borderRadius: "40px",
          stroke: "#611F69",
          strokeLinejoin: "round",
          transition: "0.1s ease",
          "&:hover": {
            background: "#FCB92B",
          },
          "@media screen and (max-width: 576px)": {
            fontSize: "25px",
          },
        }}
      />
    </Box>
  );
}

export default function BlogSlider({ blogs }) {
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <Container className="blog-slider-container">
      <Slider {...settings}>
        {blogs?.map((blog, index) => {
          return <BlogBox key={index} blog={blog} />;
        })}
      </Slider>
    </Container>
  );
}
