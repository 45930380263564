import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Abid from "../../images/AliceTwo.svg";
import "../home/AbidCommunication.scss";

export default function AbidCommunication() {
  return (
    <section className="communicateBack">
      <Container>
        <Box className="abid-wrapper">
          <Box className="abid-content">
            <Typography variant="h2">
              Get more business value & automate customer support with Chatbot
              tool
            </Typography>
            <Typography variantMapping="p">
              Integrate and easily build an AI chatbot with the help of our
              customer representative that will guide you through all the
              customization and implementation along the way.
            </Typography>
          </Box>
          <Box className="abid-image">
            <img loading="lazy" className="abidImage" src={Abid} alt="" />
          </Box>
        </Box>
      </Container>
    </section>
  );
}
